import { useWS } from "@components/web-socket-context/web-socket-client-provider";
import { getAnalyticsTracker } from "@lib/analytics-tracker";
import { getLocalSession } from "@lib/session-store";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  type QueryFunctionContext,
} from "@tanstack/react-query";
import {
  type PublicationEnv,
  USER_ENV_COOKIE_NAME,
} from "@utils/persisted-values/use-publication-env";
import fetch from "node-fetch";
import { useEffect } from "react";
import { Cookies } from "react-cookie";
import { getApmWeb } from "@lib/observability";

import type { GetServerSidePropsContext } from "next";
import type { ParsedUrlQuery } from "node:querystring";
import type { GraphQLError } from "graphql";
import {
  GetMethodGroupSubDomainProgressDocument,
  type GetMethodGroupSubDomainProgressQuery,
  type GetMethodGroupSubDomainProgressQueryVariables,
} from "@generated/graphql";
import type { ConfigType } from "src/config/shared";
import { useConfig } from "src/config/hook";
import type { SafeConfigType } from "src/config";
import { getConfigEndpointByEnv, getDiscoConfigEndpointByEnv } from "@utils/config";

type INextContext = GetServerSidePropsContext<ParsedUrlQuery>;

const GRAPH_QL_ERROR = "Subscription GraphQL error.";
const GRAPH_QL_CLOSE_ERROR = "Subscription close error.";

const UNAUTHENTICATED_ERROR_STRING = "Unauthenticated";
export const FETCH_ERROR_STRING = "Failed to fetch";

const ERROR_LOCATION = "react-query";

// Create a client
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: (err, query) => {
      if (err.message === UNAUTHENTICATED_ERROR_STRING) {
        return;
      }

      getApmWeb().captureError(err, err.name, {
        message: err.message,
        source: ERROR_LOCATION,
        variables: JSON.stringify(query.queryKey[1] || {}),
      });
    },
  }),
  mutationCache: new MutationCache({
    // biome-ignore lint/suspicious/noExplicitAny: We don't know the type of the object
    onError: (err: any, variables, _context, mutation) => {
      if (err.message === UNAUTHENTICATED_ERROR_STRING) {
        return;
      }

      getApmWeb().captureError(
        new Error(
          `${(err as Error).message} - with variables ${JSON.stringify(
            variables || {},
          )} in mutation ${mutation}`,
        ),
        ERROR_LOCATION,
      );
    },
  }),
});

export const REACT_QUERY_OPTS_15MINSTALE = {
  staleTime: 1000 * 60 * 15,
  cacheTime: 1000 * 60 * 15,
};

const getEndpointByEnv = (config: SafeConfigType): string => {
  const { graphQLEndpoint, graphQLEndpointStaging } = config;
  const endpoint = getConfigEndpointByEnv(graphQLEndpoint, graphQLEndpointStaging, config);

  if (endpoint) {
    return endpoint;
  }

  getApmWeb().captureError(new Error("production graphql endpoint not set"), ERROR_LOCATION);
  throw new Error("production graphql endpoint not set");
};

const getDiscoEndpointByEnv = (config: ConfigType, pubEnv?: PublicationEnv): string => {
  const { discoGraphQLEndpoint, discoGraphQLEndpointStaging } = config;
  const endpoint = getDiscoConfigEndpointByEnv(
    discoGraphQLEndpoint,
    discoGraphQLEndpointStaging,
    config,
    pubEnv,
  );

  if (endpoint) {
    return endpoint;
  }

  getApmWeb().captureError(new Error("production disco graphql endpoint not set"), ERROR_LOCATION);
  throw new Error("production disco graphql endpoint not set");
};

const getWideviewGraphQLEndpointByEnv = (config: SafeConfigType): string => {
  const { wideviewGraphQLEndpoint, wideviewGraphQLEndpointStaging } = config;
  const endpoint = getConfigEndpointByEnv(
    wideviewGraphQLEndpoint,
    wideviewGraphQLEndpointStaging,
    config,
  );

  if (endpoint) {
    return endpoint;
  }

  getApmWeb().captureError(
    new Error("production wideview graphql endpoint not set"),
    ERROR_LOCATION,
  );
  throw new Error("production wideview graphql endpoint not set");
};

// biome-ignore lint/suspicious/noExplicitAny: type is unknown
export const graphQlRequest = async <TData = any, TVariables = any>({
  query,
  variables,
  token,
  options,
  productAnalyticsSessionId,
  pubEnv,
  config,
}: {
  query: string;
  variables?: TVariables;
  token: string;
  options?: RequestInit["headers"];
  productAnalyticsSessionId?: string;
  nextContext?: INextContext;
  pubEnv?: PublicationEnv;
  config: SafeConfigType;
  // biome-ignore lint/suspicious/noExplicitAny: type is variable
}): Promise<{ data: TData; errors: any }> => {
  const opts = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: token || "",
      ...(productAnalyticsSessionId ? { "analytics-session-id": productAnalyticsSessionId } : {}),
      ...(pubEnv ? { "pub-env": pubEnv } : {}),
      ...(options || []),
      // biome-ignore lint/suspicious/noExplicitAny: We don't know the type of the object
    } as any,
    body: JSON.stringify({
      query,
      variables,
    }),
  };

  const res = await fetch(getEndpointByEnv(config), opts);

  return await res.json();
};

// biome-ignore lint/suspicious/noExplicitAny: type is unknown
export const discoGraphQlRequest = async <TData = any, TVariables = any>({
  query,
  variables,
  options,
  productAnalyticsSessionId,
  role,
  pubEnv,
  config,
}: {
  query: string;
  variables?: TVariables;
  role: "TEACHER" | "STUDENT";
  options?: RequestInit["headers"];
  productAnalyticsSessionId?: string;
  nextContext?: INextContext;
  pubEnv?: PublicationEnv;
  config: ConfigType;
  // biome-ignore lint/suspicious/noExplicitAny: type is variable
}): Promise<{ data: TData; errors: any }> => {
  const opts = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      role,
      caller: "jcp",
      ...(productAnalyticsSessionId ? { "analytics-session-id": productAnalyticsSessionId } : {}),
      ...(options || []),
      // biome-ignore lint/suspicious/noExplicitAny: We don't know the type of the object
    } as any,
    body: JSON.stringify({
      query,
      variables,
    }),
  };

  const res = await fetch(getDiscoEndpointByEnv(config, pubEnv), opts);

  return await res.json();
};

// biome-ignore lint/suspicious/noExplicitAny: type is unknown
const wideviewGraphQlRequest = async <TData = any, TVariables = any>({
  query,
  variables,
  token,
  options,
  productAnalyticsSessionId,
  pubEnv,
  config,
}: {
  query: string;
  variables?: TVariables;
  token: string;
  options?: RequestInit["headers"];
  productAnalyticsSessionId?: string;
  nextContext?: INextContext;
  pubEnv?: PublicationEnv;
  config: SafeConfigType;
  // biome-ignore lint/suspicious/noExplicitAny: type is variable
}): Promise<{ data: TData; errors: any }> => {
  const opts = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: token || "",
      ...(productAnalyticsSessionId ? { "analytics-session-id": productAnalyticsSessionId } : {}),
      ...(pubEnv ? { "pub-env": pubEnv } : {}),
      ...(options || []),
      // biome-ignore lint/suspicious/noExplicitAny: We don't know the type of the object
    } as any,
    body: JSON.stringify({
      query,
      variables,
    }),
  };

  const res = await fetch(getWideviewGraphQLEndpointByEnv(config), opts);

  return await res.json();
};

// Changing the name of this function requires to update `codegen.yml` and `disco-codegen.yml` configuration
export const useFetchData = <TData, TVariables>(
  query: string,
  options?: RequestInit["headers"],
): ((
  variables?: TVariables,
  queryContext?: QueryFunctionContext,
  nextContext?: INextContext | undefined,
) => Promise<TData>) => {
  const tracker = getAnalyticsTracker();
  const productAnalyticsSessionId = tracker?.getSessionId();
  const config = useConfig();

  return async (variables, _queryContext, nextContext) => {
    const { session, status } = getLocalSession();
    const token = session ? `Bearer ${session?.idToken}` : undefined;
    const cookies = new Cookies(nextContext?.req.headers.cookie);

    if (status !== "authenticated") {
      getApmWeb().captureError(
        new Error(
          `The session has an unexpected status.\nStatus: ${status}\nHas Token: ${!!token}\nOperation: ${query}\nVariables: ${variables}`,
        ),
        ERROR_LOCATION,
      );
    }

    if (!token) {
      getApmWeb().captureError(
        new Error(`Unable to get a valid session.\nOperation: ${query}\nVariables: ${variables}`),
        ERROR_LOCATION,
      );
      throw new Error(UNAUTHENTICATED_ERROR_STRING);
    }

    const json = await graphQlRequest<TData, TVariables>({
      query,
      variables,
      token,
      options,
      productAnalyticsSessionId,
      nextContext,
      pubEnv: cookies.get(USER_ENV_COOKIE_NAME),
      config,
    });

    if (json.errors) {
      const isUnauthenticated = json.errors?.[0].extensions?.response?.statusCode === 401;
      if (isUnauthenticated) {
        throw new Error(UNAUTHENTICATED_ERROR_STRING);
      }

      const { message } = json.errors[0] || "Error..";
      throw new Error(message);
    }

    return json.data;
  };
};

export const useWideviewFetchData = <TData, TVariables>(
  query: string,
  options?: RequestInit["headers"],
): ((
  variables?: TVariables,
  queryContext?: QueryFunctionContext,
  nextContext?: INextContext | undefined,
) => Promise<TData>) => {
  const tracker = getAnalyticsTracker();
  const productAnalyticsSessionId = tracker?.getSessionId();
  const config = useConfig();

  return async (variables, _queryContext, nextContext) => {
    const { session, status } = getLocalSession();
    const token = session ? `Bearer ${session?.idToken}` : undefined;
    const cookies = new Cookies(nextContext?.req.headers.cookie);

    if (status !== "authenticated") {
      getApmWeb().captureError(
        new Error(
          `The session has an unexpected status.\nStatus: ${status}\nHas Token: ${!!token}\nOperation: ${query}\nVariables: ${variables}`,
        ),
        ERROR_LOCATION,
      );
    }

    if (!token) {
      getApmWeb().captureError(
        new Error(`Unable to get a valid session.\nOperation: ${query}\nVariables: ${variables}`),
        ERROR_LOCATION,
      );
      throw new Error(UNAUTHENTICATED_ERROR_STRING);
    }

    const json = await wideviewGraphQlRequest<TData, TVariables>({
      query,
      variables,
      token,
      options,
      productAnalyticsSessionId,
      nextContext,
      pubEnv: cookies.get(USER_ENV_COOKIE_NAME),
      config,
    });

    if (json.errors) {
      const isUnauthenticated = json.errors?.[0].extensions?.response?.statusCode === 401;
      if (isUnauthenticated) {
        throw new Error(UNAUTHENTICATED_ERROR_STRING);
      }

      const { message } = json.errors[0] || "Error..";
      throw new Error(message);
    }

    return json.data;
  };
};

export const useSubscription = <T>(
  subscription: string,
  memoizedVariables: Record<string, unknown> | undefined,
  disabled: boolean,
  memoizedOnNewData: (data: T) => void,
) => {
  const wsClient = useWS();
  useEffect(() => {
    if (disabled) return;
    const payload: { query: string; variables?: Record<string, unknown> } = { query: subscription };
    if (memoizedVariables) {
      payload.variables = memoizedVariables;
    }

    const unsubscribe = wsClient?.subscribe<T>(payload, {
      next: (data) => {
        memoizedOnNewData(data as T);
      },
      error: (error) => {
        if (error instanceof CloseEvent) {
          getApmWeb().captureError(
            new Error(`${GRAPH_QL_CLOSE_ERROR} Code: ${error.code}. Reason: ${error.reason || ""}`),
            ERROR_LOCATION,
          );
        } else if (error instanceof Error) {
          getApmWeb().captureError(new Error("Subscription error"), ERROR_LOCATION);
        } else {
          const graphQlError = error as GraphQLError[];
          getApmWeb().captureError(
            new Error(`${GRAPH_QL_ERROR}\n${graphQlError.map((err) => err.message).join(", ")}`),
            ERROR_LOCATION,
          );
        }
      },
      complete: () => {},
    });

    return () => unsubscribe?.();
  }, [disabled, subscription, wsClient, memoizedVariables, memoizedOnNewData]);
};

export const fetchMethodGroupSubDomainProgress = async (
  variables: GetMethodGroupSubDomainProgressQueryVariables,
) => {
  const { studentIds, chapterIds, methodGroupId, classroomId, subDomainId } = variables;

  const fetcher = useFetchData<
    GetMethodGroupSubDomainProgressQuery,
    GetMethodGroupSubDomainProgressQueryVariables
  >(GetMethodGroupSubDomainProgressDocument);

  const result = await fetcher({ studentIds, chapterIds, methodGroupId, classroomId, subDomainId });
  return result?.methodGroup;
};
