import type { SafeConfigType } from "src/config";
import type { PublicationEnv } from "@utils/persisted-values/use-publication-env";

export const isTestEnv = ({ ilEnv }: { ilEnv: SafeConfigType["ilEnv"] }) =>
  ilEnv.startsWith("test");
export const isLocalEnv = ({ ilEnv }: { ilEnv: SafeConfigType["ilEnv"] }) => ilEnv === "local";
export const isStagingEnv = ({ ilEnv }: { ilEnv: SafeConfigType["ilEnv"] }) => ilEnv === "staging";
export const isProductionEnv = ({ ilEnv }: { ilEnv: SafeConfigType["ilEnv"] }) =>
  ilEnv === "production";

export type secretKey =
  | "IDP_SESSION_SECRET"
  | "IDP_CLIENT_SECRET"
  | "IDP_CLIENT_SECRET_NOORDHOFF"
  | "IDP_CLIENT_SECRET_LIBER"
  | "IDP_CLIENT_SECRET_PLANTYN"
  | "IDP_CLIENT_SECRET_STAGING"
  | "ELASTIC_API_KEY"
  | "ELASTIC_NODE";

function fetchSecret(key: secretKey) {
  switch (key) {
    case "IDP_SESSION_SECRET":
      return process.env.IDP_SESSION_SECRET;

    case "IDP_CLIENT_SECRET":
    case "IDP_CLIENT_SECRET_NOORDHOFF":
      return process.env.IDP_CLIENT_SECRET;

    case "IDP_CLIENT_SECRET_LIBER":
      return process.env.IDP_CLIENT_SECRET_LIBER;

    case "IDP_CLIENT_SECRET_PLANTYN":
      return process.env.IDP_CLIENT_SECRET_PLANTYN;

    case "IDP_CLIENT_SECRET_STAGING":
      return process.env.IDP_CLIENT_SECRET_STAGING;

    case "ELASTIC_API_KEY":
      return process.env.ELASTIC_API_KEY;

    case "ELASTIC_NODE":
      return process.env.ELASTIC_NODE;

    default:
      console.warn("unsupported secret key found!: ", key);
      return undefined;
  }
}

export function getSecret(key: secretKey): string {
  const value = fetchSecret(key);
  return value ?? "";
}

// We don't use isEmpty from lodash here because middleware atm doesn't support it (case of edge runtime)
// https://github.com/lodash/lodash/issues/5394
const isEmpty = (v: string) => !(v && v !== "");

export const getConfigEndpointByEnv = (url: string, stagingUrl: string, config: SafeConfigType) => {
  if (isStagingEnv(config)) {
    return stagingUrl;
  }

  if (isTestEnv(config)) {
    return "https://test.com";
  }

  if (!isEmpty(url)) {
    return url;
  }
  return undefined;
};

export const getDiscoConfigEndpointByEnv = (
  url: string,
  stagingUrl: string,
  config: SafeConfigType,
  pubEnv?: PublicationEnv,
) => {
  if (pubEnv === "staging") {
    return stagingUrl;
  }

  if (isTestEnv(config)) {
    return "https://test.com";
  }

  if (!isEmpty(url)) {
    return url;
  }
  return undefined;
};
