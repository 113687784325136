import { FETCH_ERROR_STRING } from "@lib/react-query";
import type { TFunction } from "next-i18next";
import { useEffect } from "react";
import type {
  DataErrorTypes,
  PlatformErrorCodes,
  PlatformErrorTypes,
} from "@components/platform-error/platform-error-types";
import { Stack, type Theme } from "@infinitaslearning/pixel-design-system";
import { useObservabilityWeb } from "@lib/observability";
import { unknownToError } from "@utils/common";
import { PepTypography } from "@components/pep-typography";

type ErrorType = Error | unknown | undefined;

export const useLogError = (
  title: string,
  message: string,
  errorType: PlatformErrorTypes,
  error: Error | unknown,
) => {
  const { apmWeb } = useObservabilityWeb();
  // TODO: maybe this is too much error to track, but it's something to start and iterate on
  const e = unknownToError(error);
  // biome-ignore lint/correctness/useExhaustiveDependencies: we don't want log the error multiple times
  useEffect(() => {
    apmWeb.captureError(e, e.name, {
      title,
      message,
      errorType,
      source: "use-log-error",
    });
  }, []);
};

const getIsClientError = (error: ErrorType): boolean => {
  const isOfTypeError = error instanceof Error;
  if (!isOfTypeError) {
    return false;
  }
  return !!error?.message?.includes(FETCH_ERROR_STRING);
};
export const getErrorTitle = (tFunction: TFunction, error: ErrorType): string => {
  const isClientError = getIsClientError(error);
  return tFunction(isClientError ? "platform-error.titleClient" : "platform-error.title");
};

export const getErrorMessage = (
  tFunction: TFunction,
  error: ErrorType,
  dataErrorType?: DataErrorTypes,
): string => {
  const isClientError = getIsClientError(error);
  if (dataErrorType) {
    const dataErrorTypeLabel = tFunction(`platform-error.data-types.${dataErrorType}`);
    return tFunction("platform-error.data-error-message", { dataType: dataErrorTypeLabel });
  }
  return tFunction(isClientError ? "platform-error.messageClient" : "platform-error.message");
};

export const getErrorMessageTSX = (
  tFunction: TFunction,
  errorCode: PlatformErrorCodes | undefined,
  customMessage: string | undefined,
  messageToBeDisplayed: string,
) => {
  const BaseMessageTSX = <PepTypography variant="body1">{messageToBeDisplayed}</PepTypography>;
  if (errorCode === undefined || customMessage) {
    return BaseMessageTSX;
  }
  return (
    <Stack
      sx={{
        flexDirection: "column",
        gap: 0.5,
      }}
    >
      <PepTypography
        variant="body1Bold"
        t={tFunction}
        translationKey="platform-error.error-code"
        tOptions={{ code: errorCode }}
      />
      {BaseMessageTSX}
    </Stack>
  );
};

export const getGradientBackground = (theme: Theme) =>
  `linear-gradient(180deg, ${theme.pixel.color.palette.neutrals[90]} 0%, 
            ${theme.pixel.color.palette.neutrals[100]} 50%, 
            ${theme.pixel.color.palette.neutrals[90]} 100%)`;
