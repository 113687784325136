import { useState, useEffect } from "react";

// TEMP: usetiful doesnt return an actual error message will contact them about it later and remove this
const USETIFUL_ERROR = "null";
export const ABORT_ROUTE_ERROR = "Abort route change";

const VALID_ERROR_MESSAGES = [ABORT_ROUTE_ERROR, USETIFUL_ERROR];

const SURVEY_ERROR = "Invalid survey input";
const APM_WEB_ERROR_PARTIAL = "TypeError: e.map is not a function";

/** Errors that only can be partially checked due to containing variables */
const PARTIALLY_VALID_ERROR_MESSAGES: string[] = [APM_WEB_ERROR_PARTIAL, SURVEY_ERROR];

const isValidError = (error: Error | undefined) => {
  const hasValidError = VALID_ERROR_MESSAGES.includes(String(error));
  const hasPartiallyValidError = PARTIALLY_VALID_ERROR_MESSAGES.some((message) =>
    String(error?.toString()).includes(message),
  );
  return hasValidError || hasPartiallyValidError;
};

const useGlobalErrorHandler = () => {
  const [globalError, setGlobalError] = useState<Error | undefined>(undefined);
  const [hasGlobalError, setHasGlobalError] = useState(false);

  useEffect(() => {
    const handleError = (event: ErrorEvent) => {
      setGlobalError(event.error);
      setHasGlobalError(!isValidError(event.error));
    };

    if (typeof window !== "undefined") {
      // After a lot of testing I noticed the window.onerror is not setting correctly the
      // globalError instead in the event listener is working fine
      window.addEventListener("error", handleError);

      window.onunhandledrejection = (event) => {
        setGlobalError(event.reason);
        setHasGlobalError(!isValidError(event.reason));
      };
    }

    return () => {
      window.removeEventListener("error", handleError);
      window.onunhandledrejection = null;
    };
  }, []);

  return { globalError, hasGlobalError, isValidError };
};

export default useGlobalErrorHandler;
